<script setup lang="ts">
import { ValidationProvider } from 'vee-validate'
import { ref, computed, onMounted, nextTick, watch } from 'vue'
import { getExtendedGraphemeClusters } from '/~/utils/unicode-string'
import BaseField from '/~/components/base/field/base-field.vue'
import { useInput } from '/~/composables/base'
import './payload'
import './base-textarea.scss'

const props = withDefaults(
  defineProps<{
    // BaseInputProps
    name?: string
    label?: string
    icon?: string
    error?: string | string[]
    value?: string | number
    placeholder?: string
    description?: string
    required?: boolean
    requiredAsterisk?: boolean
    disabled?: boolean
    validation?: Record<string, any>
    // Component specific props
    mask?: Record<string, any> | null
    bolder?: boolean
    rows?: number
    maxRows?: number
    maxlength?: string | number
    lineHeight?: number
    focused?: boolean
    look?: 'simple' | 'recroom' | null
    entryClass?: string
  }>(),
  {
    // BaseInputProps defaults
    name: '',
    label: '',
    icon: '',
    error: '',
    value: '',
    placeholder: '',
    description: '',
    required: false,
    requiredAsterisk: true,
    disabled: false,
    validation: () => ({}),
    // Component specific defaults
    mask: null,
    bolder: false,
    rows: 1,
    maxRows: 10,
    maxlength: '',
    lineHeight: 24,
    focused: false,
    look: null,
    entryClass: 'rounded',
  }
)

const emit = defineEmits(['input', 'focus', 'blur'])

const input = ref(null)
const field = ref(null)

const localValue = ref('')
const symbolsCount = ref(0)

const {
  validateOnInput,
  isFocused,
  id,
  onFocus,
  onBlur,
  validationProviderRef,
} = useInput(props, emit)

const counter = computed(() => `${symbolsCount.value}/${props.maxlength}`)

const bindings = computed(() => {
  const attrs = {} as Record<string, string>

  if (props.error || props.description) {
    attrs['aria-describedby'] = `${id.value}-message`
  }

  return attrs
})

function setLocalValue(event: Event) {
  const { value } = event.target as HTMLTextAreaElement
  const { maxlength } = props
  const clusters = getExtendedGraphemeClusters(String(value))
  const clustersCount = clusters.length
  let resultedValue = value
  let resultedSymbolsCount = clustersCount

  if (input.value) {
    input.value.style.height = input.value?.style?.minHeight
    nextTick(() => {
      if (!input.value) return
      input.value.style.height = input.value.scrollHeight + 'px'

      if (!isFocused.value) {
        input.value.focus()
      }
    })
  }

  if (event.target.scrollHeight) {
    event.target.style.height = event.target.scrollHeight + 'px'
  }

  if (maxlength !== '' && clustersCount > maxlength) {
    if (event.preventDefault) {
      event.preventDefault()
    }

    resultedValue = clusters.slice(0, maxlength).join('')
    resultedSymbolsCount = maxlength
  }

  symbolsCount.value = resultedSymbolsCount
  localValue.value = resultedValue

  validateOnInput(resultedValue)
  emit('input', resultedValue)
}

// Watch for value changes
function updateLocalValue() {
  if (props.value !== localValue.value) {
    setLocalValue({ target: { value: props.value } })
  }
}

// Setup watchers and lifecycle hooks
onMounted(() => {
  updateLocalValue()

  if (input.value) {
    input.value.style.height = input.value.scrollHeight + 'px'
  }

  nextTick(() => {
    if (props.focused) {
      input.value?.focus()
    }
  })
})

watch(() => props.value, updateLocalValue, { immediate: true })
</script>

<template>
  <validation-provider
    ref="validationProviderRef"
    v-slot="{ errors }"
    v-bind="validation"
    :name="validation.name ? validation.name : label"
    slim
    :detect-input="false"
  >
    <base-field
      ref="field"
      :input-id="id"
      :label="label"
      :required="required"
      :required-asterisk="requiredAsterisk"
      :disabled="disabled"
      :focused="isFocused"
      :description="description"
      :error="errors[0] || error"
      :value="localValue"
      :style="{
        lineHeight: `${lineHeight}px`,
      }"
      :icon="icon"
      :expanded="isFocused || !!value || !!placeholder"
      class="base-textarea"
      :class="look && `base-textarea--${look}`"
      :floated="look === 'simple'"
      :border="look !== 'simple'"
      :entry-class="entryClass"
    >
      <textarea
        :id="id"
        ref="input"
        v-bind="bindings"
        :value="localValue"
        :style="{
          minHeight: rows * lineHeight + (look !== 'simple' ? 16 : 2) + 'px',
          maxHeight: maxRows * lineHeight + 'px',
          resize: 'none',
        }"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :rows="rows"
        :name="name"
        :disabled="disabled"
        class="base-textarea__inner"
        @input="setLocalValue"
        @blur="onBlur"
        @focus="onFocus"
      />
      <div v-if="maxlength" class="base-textarea__counter">
        {{ counter }}
      </div>
    </base-field>
  </validation-provider>
</template>
